import {Sortable} from '@shopify/draggable';

export function matchGame() {
    const games = document.querySelectorAll( '.steveworks-matchgame' );
    if ( ! games ) {
        return;
    }

    games.forEach( (game) => {
        const gameboard = game.querySelector('.game-images');
        const containerSelector = '.game-column.is-sortable';
        const cols   = game.querySelector(containerSelector);
        const answer = gameboard.dataset.rows;
        
        checkAnswers();

        const sortable = new Sortable( cols, {
            draggable: '.game-image',
            mirror: {
                appendTo: containerSelector,
                constrainDimensions: true,
            },
        });

        sortable.on('sortable:stop', () => {
            setTimeout( checkAnswers, 250 );
        });

        function checkAnswers() {
            const leftRows = game.querySelectorAll('.game-column.left-column .game-image');
            const rows = cols.querySelectorAll('.game-image:not(.draggable-original):not(.draggable-source--is-dragging):not(.draggable-mirror)');
            const answers = answer.split('|');
            const success = game.querySelector('.success-message');
            let score     = 0;

            rows.forEach( (element, key) => {
                if ( element.dataset.row === answers[key] ) {
                    rows[key].classList.add('is-correct');
                    leftRows[key].classList.add('is-correct');
                    score += 1;
                } else {
                    rows[key].classList.remove('is-correct');
                    leftRows[key].classList.remove('is-correct');
                }
                console.log( element.dataset.row, answers[key], leftRows[key] );
            });

            if ( score === answers.length ) {
                success.classList.add('game-over');
                const closeButton = success.querySelector('.close-message');
                const resetButton = game.querySelector('.reset-game');
                closeButton?.addEventListener('click', (e) => {
                    success.classList.remove('game-over');
                    sortable.destroy();
                    resetButton.classList.add('show-reset');
                    resetButton.addEventListener('click', (e) => {
                        window.location.reload();
                    });
                });
            } else {
                success.classList.remove('game-over');
            }
        }
    });
}
