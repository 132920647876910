/**
 * File wprest.js.
 *
 * WP Rest helper.
 *
 * @package gmns
 */

export const WPRest = {
    get( endpoint ) {
        return window.fetch( endpoint, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        } )
        .then( this._handleError )
        .then( this._handleContentType )
        .catch( this._throwError );
    },
  
    post( endpoint, body ) {
        return window.fetch( endpoint, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify( body ),
        } )
        .then( this._handleError )
        .then( this._handleContentType )
        .catch( this._throwError );
    },
  
    _handleError( err ) {
        return err.ok ? err : Promise.reject( err.statusText )
    },
  
    _handleContentType( res ) {
        const contentType = res.headers.get( 'content-type' );
        if( contentType && contentType.includes( 'application/json' ) ) {
            return res.json()
        }
        return Promise.reject( 'Oops, we haven\'t got JSON!' )
    },
  
    _throwError( err ) {
        throw new Error( err );
    }
}
