/**
 * Custom testimonial linking with JS.
 */

export function testimonialLinking() {

    let archivePage = '/what-they-say/';

    let isPortfolioItem = document.querySelector( 'body.single-portfolio' );
    if ( isPortfolioItem ) {
        let testimonial = document.querySelector( 'blockquote.wp-block-quote' );
        if ( testimonial ) {

            let path = window.location.href.replace(/\/$/, '');
            let slug = path.substring( path.lastIndexOf('/') + 1 );

            if ( testimonial.id !== '' ) {
                slug = testimonial.id;
            }

            testimonial.classList.add( 'linked-testimonial' );
            testimonial.addEventListener( 'click', (e) => {
                window.location.href = archivePage + '#' + slug;
            });
        }
    }

    let isTestimonialPage = document.querySelector( 'body.post-type-archive-sw_testimonial' );
    if ( isTestimonialPage ) {
        let slug = window.location.hash;
        if ( '' !== slug ) {
            let testimonial = document.querySelector( slug );
            if ( testimonial ) {
                testimonial.click();
            }
        }
    }
}
