/**
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 * 
 * @package steveworks
 */

export function tabs() {

    var pageTabs = document.querySelector('.page-tabs');

    if ( pageTabs ) {
        let mql = window.matchMedia("(min-width: 768px)");
        handleOrientationChange( mql );
        mql.addEventListener( 'change', handleOrientationChange );
    }

    function pageTabsEvent( event ) {
        if ('BUTTON' === event.target.nodeName) {
            let tab = event.target.dataset.tabSrc;
            loadTab( tab );
        }
    }

    function resetTabs( element ) {
        if ( !element.querySelector('.page-tabs-nav--button.nav-active') ) {
            element.querySelector('.page-tabs-nav--button').classList.add('nav-active');
            element.querySelector('.page-tabs-content--item').classList.add('tab-visible');
        }
    }

    function loadTab( tab ) {
        pageTabs.querySelector('.page-tabs-nav--button.nav-active').classList.remove('nav-active');
        pageTabs.querySelector('.page-tabs-content--item.tab-visible').classList.remove('tab-visible');
        pageTabs.querySelector('.page-tabs-nav--button[data-tab-src="'+tab+'"]').classList.add('nav-active');
        pageTabs.querySelector('.page-tabs-content--item[data-tab-nav="'+tab+'"]').classList.add('tab-visible');
    }

    function handleOrientationChange(mql) {
        if (mql.matches) {
            resetTabs( pageTabs );
            pageTabs.addEventListener( 'click', pageTabsEvent );
        }
    }
}
