/**
 * File dialog.js.
 *
 * Modal/dialog functionality.
 *
 * @package gmns
 */

import A11yDialog from 'a11y-dialog';
import { WPRest } from './wprest';

export function dialog() {

    // search modal, always present.
    const search = document.querySelector('#header-search');
    const search_dialog = new A11yDialog(search);

    search_dialog.on( 'show', function(event) {
        document.querySelector('.search-field').focus();
    });
    
    // testimonial modal, only visible on the "testimonial" page(s).
    const testimonial = document.querySelector('#testimonial-modal');
    if ( testimonial ) {

        const testimonial_dialog = new A11yDialog(testimonial);

        testimonial_dialog.on( 'show', function(event) {
            const target = event.detail.target;
            const opener = target.closest('[data-a11y-dialog-show]');

            const image        = document.getElementById( 'testimonial-modal--image' );
            const title        = document.getElementById( 'testimonial-modal--title' );
            const author       = document.getElementById( 'testimonial-modal--author' );
            const position     = document.getElementById( 'testimonial-modal--position' );
            const project      = document.getElementById( 'testimonial-modal--project' );
            const projectName  = document.getElementById( 'testimonial-modal--project-name' );
            const quote        = document.getElementById( 'testimonial-modal--quote' );

            WPRest.get( '/wp-json/wp/v2/sw_testimonial/' + opener.dataset.testimonialId + '?_embed' ).then( result => {


                if ( result.featured_media ) {
                    if ( result._embedded['wp:featuredmedia']['0'].media_details ) {
                        let currentImage = null;
                        if ( result._embedded['wp:featuredmedia']['0'].media_details.sizes.large ) {
                            currentImage = result._embedded['wp:featuredmedia']['0'].media_details.sizes.large;
                        } else {
                            currentImage = result._embedded['wp:featuredmedia']['0'];
                        }
                        
                        image.setAttribute('src', currentImage.source_url );
                        image.setAttribute('width', currentImage.width );
                        image.setAttribute('height', currentImage.height );
                        image.setAttribute('alt', htmlDecode( result.title.rendered ) );
                        image.classList.remove('img-loading');
                    }
                } else {
                    WPRest.get( '/wp-json/wp/v2/media/' + opener.dataset.thumbnailId ).then( media_result => {
                        if ( media_result.media_details ) {
                            let currentImage = null;
                            if ( media_result.media_details.sizes.large ) {
                                currentImage = media_result.media_details.sizes.large;
                            } else {
                                currentImage = media_result;
                            }

                            image.setAttribute('src', currentImage.source_url );
                            image.setAttribute('width', currentImage.width );
                            image.setAttribute('height', currentImage.height );
                            image.setAttribute('alt', htmlDecode( media_result.title.rendered ) );
                            image.classList.remove('img-loading');
                        }
                    });
                }

                title.innerText = htmlDecode( result.title.rendered );
                if ( result.acf && result.acf.author ) {
                    author.innerText = "- " + htmlDecode( result.acf.author );
                } else {
                    author.innerText = '';
                }
                if ( result.acf && result.acf.author_title) {
                    position.innerText = htmlDecode( result.acf.author_title );
                } else {
                    position.innerText = '';
                }

                if ( result.acf && result.acf.portfolio_item_link ) {
                    let testimonial_project = result.acf.portfolio_item_link;
                    if ( Array.isArray( result.acf.portfolio_item_link ) ) {
                        testimonial_project = result.acf.portfolio_item_link[0];
                    }
                    WPRest.get( '/wp-json/wp/v2/portfolio/' + testimonial_project ).then( result => {
                        project.setAttribute( 'href', result.link );
                        projectName.innerText = htmlDecode( result.title.rendered );
                        project.setAttribute( 'style', 'display:inline-block;' );
                    });
                }

                quote.innerHTML = result.content.rendered;

                this.dataset.loaded = true;
            } );
        });

        testimonial_dialog.on('hide', function (event) {
            const image        = document.getElementById( 'testimonial-modal--image' );
            const title        = document.getElementById( 'testimonial-modal--title' );
            const author       = document.getElementById( 'testimonial-modal--author' );
            const position     = document.getElementById( 'testimonial-modal--position' );
            const project      = document.getElementById( 'testimonial-modal--project' );
            const projectName  = document.getElementById( 'testimonial-modal--project-name' );
            const quote        = document.getElementById( 'testimonial-modal--quote' );
            
            image.setAttribute('src', '' );
            image.setAttribute('width', '' );
            image.setAttribute('height', '' );
            image.setAttribute('alt', '' );
            image.classList.add('img-loading');
            image.classList.remove('no-image');

            title.innerHTML = '<span class="placeholder loading"></span>';
            author.innerHTML = '<span class="placeholder loading"></span>';
            position.innerHTML = '<span class="placeholder loading"></span>';
            project.setAttribute( 'href', '' );
            project.setAttribute( 'style', 'display:none;' );
            projectName.innerText = '';
            quote.innerHTML = '<span class="placeholder loading large"></span>';

            this.dataset.loaded = false;
        });
    }

    // people modal, only visible on the "meet the crew" page(s).
    const people = document.querySelector('#people-modal');
    if ( people ) {

        const people_dialog = new A11yDialog(people);

        people_dialog.on( 'show', function(event) {
            const target = event.detail.target;
            const opener = target.closest('[data-a11y-dialog-show]');

            const image    = document.getElementById( 'people-modal--image' );
            const name     = document.getElementById( 'people-modal--name' );
            const position = document.getElementById( 'people-modal--position' );
            const bio      = document.getElementById( 'people-modal--bio' );

            WPRest.get( '/wp-json/wp/v2/sw_people/' + opener.dataset.peopleId + '?_embed' ).then( result => {

                if ( result.featured_media ) {
                    if ( result._embedded['wp:featuredmedia']['0'].media_details ) {
                        let currentImage = null;
                        if ( result._embedded['wp:featuredmedia']['0'].media_details.sizes.medium ) {
                            currentImage = result._embedded['wp:featuredmedia']['0'].media_details.sizes.medium;
                        } else {
                            currentImage = result._embedded['wp:featuredmedia']['0'];
                        }
                        
                        image.setAttribute('src', currentImage.source_url );
                        image.setAttribute('width', currentImage.width );
                        image.setAttribute('height', currentImage.height );
                        image.setAttribute('alt', htmlDecode( result.title.rendered ) );
                        image.classList.remove('img-loading');
                    }
                }

                name.innerText = htmlDecode( result.title.rendered );

                if ( result.acf && result.acf.position ) {
                    position.innerText = htmlDecode( result.acf.position );
                } else {
                    position.innerText = '';
                }
                bio.innerHTML = result.content.rendered;

                this.dataset.loaded = true;
            } );
        });

        people_dialog.on('hide', function (event) {
            const image    = document.getElementById( 'people-modal--image' );
            const name     = document.getElementById( 'people-modal--name' );
            const position = document.getElementById( 'people-modal--position' );
            const bio      = document.getElementById( 'people-modal--bio' );
            
            image.setAttribute('src', '' );
            image.setAttribute('width', '500' );
            image.setAttribute('height', '500' );
            image.setAttribute('alt', '' );
            image.classList.add('img-loading');
            image.classList.remove('no-image');

            name.innerHTML = '<span class="placeholder loading"></span>';
            position.innerHTML = '<span class="placeholder loading"></span>';
            bio.innerHTML = '<span class="placeholder loading large"></span>';

            this.dataset.loaded = false;
        });
    }
    
	function htmlDecode(input) {
		var doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}
}
