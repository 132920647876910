/**
 * File post-archive.js.
*
* Handles post category filtering.
*
* @package steveworks
*/

export function categoryFilter() {
	const categoryFilter = document.getElementById( 'category-filter' );

	// Return early if the navigation doesn't exist.
	if ( ! categoryFilter ) {
		return;
	}

	const button = categoryFilter.getElementsByTagName( 'button' )[ 0 ];

	// Return early if the button doesn't exist.
	if ( 'undefined' === typeof button ) {
		return;
	}

	const menu = categoryFilter.getElementsByTagName( 'ul' )[ 0 ];

	// Hide menu toggle button if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		button.style.display = 'none';
		return;
	}

	// Toggle the .toggled class and the aria-expanded value each time the button is clicked.
	button.addEventListener( 'click', function () {
		categoryFilter.classList.toggle( 'toggled' );
		menu.style.left = button.offsetLeft + 'px';

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	} );

	// Remove the .toggled class and set aria-expanded to false when the user clicks outside the navigation.
	document.addEventListener( 'click', function ( event ) {
		const isClickInside = categoryFilter.contains( event.target );

		if ( ! isClickInside ) {
			categoryFilter.classList.remove( 'toggled' );
			button.setAttribute( 'aria-expanded', 'false' );
		}
	} );
}
