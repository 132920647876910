/**
 * Main JS file for Steveworks Underscores theme
 *
 * Will be compiled into js/main.js
 */

import $ from 'jquery';
import { navigation } from './modules/navigation';
import './modules/slick';
import { dialog } from './modules/dialog';
import { testimonialLinking } from './modules/testimonial';
import { tabs } from './modules/tabs';
import { categoryFilter } from './modules/post-archive';
import { matchGame } from './modules/match-game';

// run all functions on page load.
document.addEventListener( 'DOMContentLoaded', function () {
	navigation();
	dialog();
	tabs();
	categoryFilter();
	testimonialLinking();
	matchGame();
} );
